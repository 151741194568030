import "./../styles/docsMenuTemplate.scss"
import React from "react"
import DocsLayout from "../components/docs-layout/docs-layout"
import { graphql } from "gatsby"
import DocsItemsList from "../components/docs-items-list/docs-items-list"
import {
  DOCS_TYPE_CONTENT,
  DOCS_TYPE_LINK,
  DOCS_TYPE_MENU,
} from "../utils/constants"
import DocsItemGroup from "../components/docs-item-group/docs-item-group"
import { SearchContextProvider } from "../contexts/search-context"
import SEO from "../components/seo"
import { SelectedMenuContextProvider } from "../contexts/selected-menu-context"

const DocsMenuTemplate = ({ data, pageContext }) => {
  const { currentRoute, urlParts } = pageContext
  const {
    menuItem: {
      html,
      frontmatter,
      fields: { relativePath },
    },
  } = data
  const {
    children: { edges },
  } = data
  const {
    grandChildren: { edges: grandChildrenEdges },
  } = data

  const isRoot = urlParts.length === 0

  const nodes = edges.map(edge => edge.node)
  const grandChildrenNodes = grandChildrenEdges.map(edge => edge.node)

  const menuNodes = nodes.filter(
    node => node.frontmatter.type === DOCS_TYPE_MENU
  )
  const contentNodes = nodes.filter(
    node =>
      node.frontmatter.type === DOCS_TYPE_CONTENT ||
      node.frontmatter.type === DOCS_TYPE_LINK
  )

  for (const menuNode of menuNodes) {
    menuNode.children = grandChildrenNodes.filter(node =>
      node.fields.relativePath.startsWith(menuNode.fields.relativePath)
    )
  }

  return (
    <DocsLayout relativePath={relativePath} urlParts={urlParts}>
      <SEO
        title={`${frontmatter.title} | Volterra Docs`}
        isArticle={false}
        dontIndex={frontmatter.linked === false}
      />
      <div className="docs-menu-container">
        <h1 className="docs-menu-container-header">{frontmatter.title}</h1>
        <div className="data" dangerouslySetInnerHTML={{ __html: html }} />
        {contentNodes && (
          <DocsItemsList nodes={contentNodes} routePrefix={currentRoute} />
        )}
        {menuNodes && !isRoot && (
          <DocsItemGroup routePrefix={currentRoute} menuNodes={menuNodes} />
        )}
        {menuNodes && isRoot && (
          <DocsItemsList nodes={menuNodes} routePrefix={currentRoute} />
        )}
      </div>
    </DocsLayout>
  )
}

export const pageQuery = graphql`
  query(
    $relativePath: String!
    $childRegex: String!
    $grandChildRegex: String!
  ) {
    menuItem: markdownRemark(
      fields: { relativePath: { eq: $relativePath } }
      frontmatter: { type: { eq: "menu" } }
    ) {
      html
      fields {
        relativePath
        category
      }
      frontmatter {
        title
        type
        slug
      }
    }
    children: allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "docs" }
          relativePath: { regex: $childRegex }
        }
        frontmatter: {
          draft: { in: [false, null] }
          linked: { in: [true, null] }
        }
      }
      sort: { order: ASC, fields: fields___relativePath }
    ) {
      edges {
        node {
          fields {
            relativePath
          }
          frontmatter {
            title
            type
            excerpt
            slug
            externalLink
          }
        }
      }
    }
    grandChildren: allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "docs" }
          relativePath: { regex: $grandChildRegex }
        }
        frontmatter: {
          draft: { in: [false, null] }
          linked: { in: [true, null] }
        }
      }
      sort: { order: ASC, fields: fields___relativePath }
    ) {
      edges {
        node {
          fields {
            relativePath
          }
          frontmatter {
            title
            type
            excerpt
            slug
            externalLink
            linked
          }
        }
      }
    }
  }
`

const DocsMenuTemplateContainer = props => (
  <SelectedMenuContextProvider>
    <SearchContextProvider>
      <DocsMenuTemplate {...props} />
    </SearchContextProvider>
  </SelectedMenuContextProvider>
)

export default DocsMenuTemplateContainer
