import "./docs-item-group.scss"
import React, { useContext } from "react"
import DocsItemsList from "../docs-items-list/docs-items-list"
import { Link } from "gatsby"
import { SearchContext } from "../../contexts/search-context"
import VesHighlighter from "../highlighter"

const DocsItemGroup = ({ routePrefix, menuNodes }) => {
  const searchContext = useContext(SearchContext)

  return (
    <div className="docs-item-group-container">
      {menuNodes.map((node, index) => {
        const isLast = ++index === menuNodes.length
        return (
          <div key={node.frontmatter.slug} className="item">
            <Link
              aria-label={node.frontmatter.title}
              to={`${routePrefix}/${node.frontmatter.slug}`}>
              <h2>
                {searchContext.query !== "" && (
                  <VesHighlighter
                    searchTerm={searchContext.query}
                    text={node.frontmatter.title}
                  />
                )}
                {searchContext.query === "" && node.frontmatter.title}
              </h2>
            </Link>
            <DocsItemsList
              nodes={node.children}
              routePrefix={`${routePrefix}/${node.frontmatter.slug}`}
            />

            {!isLast && <hr />}
          </div>
        )
      })}
    </div>
  )
}

export default DocsItemGroup
