import "./docs-item.scss"
import React, { useContext } from "react"
import { Link } from "gatsby"
import { SearchContext } from "../../contexts/search-context"
import VesHighlighter from "../highlighter"
import ArrowRightSvg from "../svg/arrow-right"

const DocsItem = ({ routePrefix, title, slug, excerpt, externalLink }) => {
  const searchContext = useContext(SearchContext)

  const LinkComponent = !!externalLink ? "a" : Link
  const linkComponentProps = !!externalLink
    ? {
        href: externalLink,
      }
    : {
        to: `${
          routePrefix.endsWith("/") ? routePrefix : routePrefix + "/"
        }${slug}`,
      }

  return (
    <LinkComponent aria-label={title} {...linkComponentProps} className="item">
      <ArrowRightSvg />
      <p className="title">
        {searchContext.query === "" && title}
        {searchContext.query !== "" && (
          <VesHighlighter searchTerm={searchContext.query} text={title} />
        )}
      </p>
      {excerpt && searchContext.query === "" && (
        <p className="excerpt">{excerpt}</p>
      )}
      {excerpt && searchContext.query !== "" && (
        <span className="excerpt">
          <VesHighlighter searchTerm={searchContext.query} text={excerpt} />
        </span>
      )}
    </LinkComponent>
  )
}

export default DocsItem
