import styles from "./docs-items-list.module.scss"
import React from "react"
import DocsItem from "../docs-item/docs-item"

const DocsItemsList = ({ nodes, routePrefix }) => {
  return (
    <div className={styles.itemsListContainer}>
      {nodes.map(node => {
        return (
          <DocsItem
            key={node.frontmatter.slug}
            routePrefix={routePrefix}
            title={node.frontmatter.title}
            slug={node.frontmatter.slug}
            externalLink={node.frontmatter.externalLink}
            excerpt={node.frontmatter.excerpt}
          />
        )
      })}
    </div>
  )
}

export default DocsItemsList
